import { ApplicationInsights, PageView, RemoteDependencyData, SeverityLevel, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useClaims } from '../hooks/TokenProvider';

const _developmentKey = "74a07d9e-ab7f-4c3a-a55f-b22b6f0c916c";
const _stagingKey = "89b23aba-2926-422a-9794-1cbed2b12d44";
const _productionKey = "6a2cd89d-ab68-41ba-b715-d16b367e84fb";
const _iKeys = new Map<string, string>([
  ["localhost:3000", _developmentKey],
  ["localhost:7071", _developmentKey],
  ["dev.higherknowledge.in:7071", _developmentKey],
  ["stupefied-turing.higherknowledge.in", _stagingKey],
]);
const _iKey = _iKeys.get(window.location.host) || _productionKey;

const _guidRe = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/ig;
const _accessTokenRe = /access_token=[^&#]*/ig;
const _uriAuth = /^https?:\/\/[a-z0-9.-]+(:[0-9]+)?/i;
const _insights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    autoTrackPageVisitTime: true,
    instrumentationKey: _iKey,
    distributedTracingMode: DistributedTracingModes.W3C
  }
});
_insights.loadAppInsights();
_insights.addTelemetryInitializer(item => {
  if (item.name == PageView.envelopeType && item.baseData) {
    const originalName = item.baseData.name;
    const originalUri = item.baseData.uri;
    item.data = item.data ?? {};
    item.data["Title"] = originalName;
    if (typeof (originalUri) === "string") {
      const operationName = _toOperationName(originalUri, item.data);
      item.baseData.name = operationName;
      item.tags = item.tags ?? [];
      item.tags["ai.operation.name"] = operationName;
    }
  } else if (item.name == RemoteDependencyData.envelopeType && item.baseData) {
    const originalName = item.baseData.name;
    item.data = item.data ?? {};
    if (typeof (originalName) === "string") {
      item.baseData.name = _toOperationName(originalName, item.data);
      item.baseData.target = _redact(item.baseData.target);
      if (item.baseData.name != originalName) {
        item.data["Dependency URL"] = _redact(originalName);
      }
    }
  }
});
_insights.trackPageView();

function _redact(value: string) {
  return value?.replace(_accessTokenRe, "access_token=[token]");
}

function _toOperationName(name: string, data: { [key: string]: any }) {
  name = name.split("?")[0].split("#")[0].replace(_guidRe, "[id]").replace(_uriAuth, "");
  if (name.startsWith("/go/")) {
    data["Code"] = name.split("/")[2];
    name = "/go/[code]";
  }
  return name;
}

const Context = createContext<ApplicationInsights>(_insights);

export function useAppInsights() {
  return useContext(Context);
}

type Properties = { [key: string]: any };

function _getEvents(events: string | undefined) {
  return events ? events.split(';').map(_ => _.trim()).filter(_ => !!_) : [];
}

export function useTrackedEvent(events?: string, baseProperties: Properties = {}) {
  const insights = useContext(Context);
  useEffect(() => {
    _getEvents(events).forEach(name => {
      insights.trackEvent({ name }, { ...baseProperties })
    });
  }, [events]);
  function track<T>(callback: ((args: T) => void) | undefined, events: string, customProperties: Properties = {}) {
    return (args: T) => {
      _getEvents(events).forEach(name => {
        insights.trackEvent({ name }, { ...baseProperties, ...customProperties })
      });
      return callback?.(args);
    }
  }
  return track;
}

export function useLogger(name: string, baseProperties: Properties = {}) {
  const insights = useContext(Context);
  const log = (severityLevel: SeverityLevel, message: string, properties: Properties = {}) => {
    insights.trackTrace({
      severityLevel,
      message,
      properties: {
        CategoryName: name,
        ...baseProperties,
        ...properties
      }
    });
  }
  const info = (message: string, properties: Properties) => log(SeverityLevel.Information, message, properties);
  const error = (message: string, properties: Properties) => log(SeverityLevel.Error, message, properties);
  const debug = (message: string, properties: Properties) => log(SeverityLevel.Verbose, message, properties);
  const warn = (message: string, properties: Properties) => log(SeverityLevel.Warning, message, properties);
  return { info, error, debug, warn };
}

function _trimStorage() {
  const key = "AI_buffer";
  const maxLength = 3 * 1024 * 1024;
  if (window.sessionStorage.getItem(key)?.length ?? 0 > maxLength) {
    window.sessionStorage.removeItem(key);
  }
  if (window.localStorage.getItem(key)?.length ?? 0 > maxLength) {
    window.localStorage.removeItem(key);
  }
}

export default ({ children }: { children?: ReactNode }) => {
  const [claims] = useClaims();
  if (claims?.sub) {
    _insights.setAuthenticatedUserContext(claims?.sub, claims?.orgid, true);
  } else {
    _insights.clearAuthenticatedUserContext()
  }
  _trimStorage();
  return (
    <Context.Provider value={_insights}>
      {children}
    </Context.Provider>
  );
}
